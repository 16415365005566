import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import classnames from 'classnames';
import { graphql, Link } from 'gatsby';
import Image from '@components/Image/Image';
import WelcomeMessage from '@components/Messages/WelcomeMessage';
import { PersonLinkedData } from '@components/MetaTags/LinkedData';
import SectionHeading from '@components/SectionHeading/SectionHeading';
import { getAnimationDelay } from '@lib/util';
import * as gridStyles from '@components/Grid/Grid.module.scss';
import * as styles from '@layouts/HomeLayout.module.scss';
export const query = graphql`
  query {
    artVr: file(relativePath: { eq: "media/art-vr/artvr-crop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 800)
      }
    }
    buildBall: file(
      relativePath: { eq: "media/build-ball/build-ball-crop-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 800)
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <div>
  <WelcomeMessage mdxType="WelcomeMessage" />
    </div>
    <div className={styles.chunk}>
      <h2>{`Projects / Fun`}</h2>
    </div>
    <div className={gridStyles.twoUp}>
      <div className={gridStyles.one}>
        <div className={styles.chunk} {...getAnimationDelay(2)}>
          <Link className={styles.imageLink} to="/project/build-ball" mdxType="Link">
  <Image alt="Build ball glowing green at Cadre HQ, Puck Building, NYC." image={props.data.buildBall.childImageSharp.gatsbyImageData} loading="eager" mdxType="Image" />
          </Link>
        </div>
        <div className={classnames(styles.titleDesc, styles.bbLink, styles.chunk)} {...getAnimationDelay(3)}>
          <h4><a parentName="h4" {...{
              "href": "/project/build-ball"
            }}>{`Build Ball`}</a></h4>
          <p>{`Portable glowing visual feedback orb`}</p>
        </div>
      </div>
      <div className={gridStyles.two}>
        <div className={styles.chunk} {...getAnimationDelay(4)}>
          <Link className={styles.imageLink} to="/project/art-vr" mdxType="Link">
  <Image alt="Screenshot of Art/VR virtual reality art gallery experience." image={props.data.artVr.childImageSharp.gatsbyImageData} loading="eager" mdxType="Image" />
          </Link>
        </div>
        <div className={classnames(styles.titleDesc, styles.vrLink, styles.chunk)} {...getAnimationDelay(5)}>
          <h4><a parentName="h4" {...{
              "href": "/project/art-vr"
            }}>{`Art/VR`}</a></h4>
          <p>{`Virtual Reality Art Gallery / Museum experience`}</p>
        </div>
      </div>
    </div>
    <div className={styles.chunk} {...getAnimationDelay(6)}>
  <hr />
    </div>
    <div className={gridStyles.twoUp}>
      <div className={gridStyles.one}>
        <div className={styles.chunk} {...getAnimationDelay(7)}>
          <h2>{`Recent Work`}</h2>
        </div>
        <div className={classnames(styles.chunk, styles.titleDesc)} {...getAnimationDelay(8)}>
          <h4><a parentName="h4" {...{
              "href": "/startup/verishop"
            }}>{`Verishop`}</a></h4>
          <p>{`E-commerce platform for shopping & inspiration`}</p>
        </div>
        <div className={classnames(styles.chunk, styles.titleDesc)} {...getAnimationDelay(9)}>
          <h4><a parentName="h4" {...{
              "href": "/startup/cadre"
            }}>{`Cadre`}</a></h4>
          <p>{`Real estate investment platform & secondary market`}</p>
        </div>
        <div className={classnames(styles.chunk, styles.titleDesc)} {...getAnimationDelay(10)}>
          <h4><a parentName="h4" {...{
              "href": "/startup/gigwax"
            }}>{`Gigwax`}</a></h4>
          <p>{`DJ booking marketplace & reviews platform`}</p>
        </div>
      </div>
      <div className={gridStyles.two}>
        <div className={styles.chunk} {...getAnimationDelay(11)}>
          <h2>{`Prior work`}</h2>
        </div>
        <div className={classnames(styles.chunk, styles.titleDesc)} {...getAnimationDelay(14)}>
          <h4><a parentName="h4" {...{
              "href": "/company/volpe-center"
            }}>{`Volpe`}</a></h4>
          <p>{`Crash avoidance technology & research`}</p>
        </div>
        <div className={classnames(styles.chunk, styles.titleDesc)} {...getAnimationDelay(15)}>
          <h4><a parentName="h4" {...{
              "href": "/startup/sensicast"
            }}>{`Sensicast`}</a></h4>
          <p>{`Wireless mesh sensor networking`}</p>
        </div>
        <div className={classnames(styles.chunk, styles.titleDesc)} {...getAnimationDelay(12)}>
          <h4><a parentName="h4" {...{
              "href": "/startup/pradux"
            }}>{`Pradux`}</a></h4>
          <p>{`Shoppable, embeddable photos`}</p>
        </div>
        <div className={classnames(styles.chunk, styles.titleDesc)} {...getAnimationDelay(13)}>
          <h4><a parentName="h4" {...{
              "href": "/startup/freecause"
            }}>{`FreeCause`}</a></h4>
          <p>{`Toolbars `}{`&`}{` affiliate marketing tech`}</p>
        </div>
      </div>
    </div>
    <PersonLinkedData mdxType="PersonLinkedData" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      